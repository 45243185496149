import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ALL_SOFTWARES,
  COLOR_TITLE,
  Constant,
  FIELD_COMPONENT,
  MODULE_NAME,
  MODULE_NAME_NEED_PROJECT,
  NUMBER_LAYOUT,
  TYPE_LAYOUT,
  menuComponents
} from 'app/config/constants';
import { AccountService } from 'app/core/auth/account.service';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { UserSetting } from 'app/model/entity/user-setting';
import { BusInformationDisplayEditorComponent } from 'app/module/bus-information-display-editor/bus-information-display-editor.component';
import { DashboardComponent } from 'app/module/dashboard/dashboard.component';
import { DeliveryManagerComponent } from 'app/module/delivery-manager/delivery-manager.component';
import { DestinationSignEditorComponent } from 'app/module/destination-sign-editor/destination-sign-editor.component';
import { DeviceManagerComponent } from 'app/module/device-manager/device-manager.component';
import { DigitalSignageContentEditorComponent } from 'app/module/digital-signage-content-editor/digital-signage-content-editor.component';
import { EdsEditorComponent } from 'app/module/eds-editor/eds-editor.component';
import { ExternalContentManagerComponent } from 'app/module/external-content-manager/external-content-manager.component';
import { IndexWordEditorComponent } from 'app/module/index-word-editor/index-word-editor.component';
import { LcdLayoutEditorComponent } from 'app/module/lcd-layout-editor/lcd-layout-editor.component';
import { LedLayoutEditorComponent } from 'app/module/led-layout-editor/led-layout-editor.component';
import { MasterListEditorComponent } from 'app/module/master-list-editor/master-list-editor.component';
import { MediaManagerComponent } from 'app/module/media-manager/media-manager.component';
import { ProjectManagerComponent } from 'app/module/project-manager/project-manager.component';
import { RouteListEditorComponent } from 'app/module/route-list-editor/route-list-editor.component';
import { ScheduleMergeComponent } from 'app/module/schedule-merge/schedule-merge.component';
import { ScheduleOperationManagerComponent } from 'app/module/schedule-operation-manager/schedule-operation-manager.component';
import { SignageDisplayEditorComponent } from 'app/module/signage-display-editor/signage-display-editor.component';
import { SimpleSignageEditorComponent } from 'app/module/simple-signage-editor/simple-signage-editor.component';
import { StationDisplayEditorComponent } from 'app/module/station-display-editor/station-display-editor.component';
import { TicketEditorTabSpotComponent } from 'app/module/ticket-editor/ticket-editor-tab-spot/ticket-editor-tab-spot.component';
import { TicketEditorComponent } from 'app/module/ticket-editor/ticket-editor.component';
import { TicketManagerComponent } from 'app/module/ticket-manager/ticket-manager.component';
import { TimetableEditorComponent } from 'app/module/timetable-editor/timetable-editor.component';
import { TimetableOperationManagerComponent } from 'app/module/timetable-operation-manager/timetable-operation-manager.component';
import { UserManagerComponent } from 'app/module/user-manager/user-manager.component';
import { SaveMainStateAction, SaveUserInfoAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataConfigService } from 'app/service/data-config.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { FontService } from 'app/service/font.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { UserSettingService } from 'app/service/user-setting.service';
import { UserService as UserService2 } from 'app/service/user.service';
import { LoginModalComponent } from 'app/shared/login/login.component';
import { AppState } from 'app/store/app.state';
import { JhiLanguageService } from 'ng-jhipster';
import { Subscription, forkJoin } from 'rxjs';
import { UserService } from '../../core/user/user.service';
import { Helper } from '../helper';
import { AnnouncementManagerComponent } from './../../module/announcement-manager/announcement-manager.component';
import { ScheduleRegistrationComponent } from './../../module/schedule-registration/schedule-registration.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  /**
   * entryComponents
   */
  entryComponents = [
    UserManagerComponent,
    ProjectManagerComponent,
    DeviceManagerComponent,
    IndexWordEditorComponent,
    ExternalContentManagerComponent,
    LcdLayoutEditorComponent,
    LedLayoutEditorComponent,
    MasterListEditorComponent,
    RouteListEditorComponent,
    TimetableEditorComponent,
    DestinationSignEditorComponent,
    SignageDisplayEditorComponent,
    BusInformationDisplayEditorComponent,
    StationDisplayEditorComponent,
    ScheduleRegistrationComponent,
    ScheduleMergeComponent,
    DeliveryManagerComponent,
    TicketEditorComponent,
    TicketEditorTabSpotComponent,
    MediaManagerComponent,
    DigitalSignageContentEditorComponent,
    SimpleSignageEditorComponent,
    TimetableOperationManagerComponent,
    ScheduleOperationManagerComponent,
    AnnouncementManagerComponent,
    TicketManagerComponent,
    DashboardComponent,
    LoginModalComponent,
    EdsEditorComponent
  ];

  @ViewChild('screen1') private screen1: ElementRef;
  @ViewChild('menuSoftware1') private menuSoftware1: ElementRef;

  menuComponents = menuComponents;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * COLOR_TITLE
   */
  COLOR_TITLE = COLOR_TITLE;
  /**
   * NUMBER_LAYOUTT
   */
  NUMBER_LAYOUT = NUMBER_LAYOUT;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  ALL_SOFTWARES = ALL_SOFTWARES;
  MODULE_NAME_NEED_PROJECT = MODULE_NAME_NEED_PROJECT;
  /**
   * typeLayout
   */
  typeLayout: TYPE_LAYOUT;
  /**
   * moduleName of layout 1
   */
  moduleName: string;
  /**
   * colorTask of layout 1
   */
  colorTask: string;
  /**
   * ElementRef moduleLayout1
   */
  @ViewChild('moduleLayout', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout;
  /**
   * ElementRef moduleLayout1
   */
  @ViewChild('moduleLayout_1', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout_1;
  /**
   * componentRef1
   */
  componentRef: ComponentRef<any>;
  componentRef_1: ComponentRef<any>;
  /**
   * project's id
   */
  projectId: number;

  /**
   * Constant
   */
  Constant = Constant;
  /**
   * userId
   */
  userId: string;

  commonObject: Common = new Common();
  /**
   * Constructor
   * @param componentFactoryResolver ComponentFactoryResolver
   * @param changeDetectorRef ChangeDetectorRef
   * @param renderer Renderer2
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private accountService: AccountService,
    private router: Router,
    private titleService: Title,
    private dataService: DataService,
    public readonly store: Store<AppState>,
    private userSettingService: UserSettingService,
    private menuActionService: MenuActionService,
    private userService2: UserService2,
    private dialogService: DialogService,
    public translateService: TranslateService,
    private fontService: FontService,
    private dataConfigService: DataConfigService,
    private commonService: CommonService,
    private languageService: JhiLanguageService,
    private activatedRoute: ActivatedRoute,
    private executingService: ExecutingService
  ) {}

  ngOnInit() {
    // try to log in automatically
    this.accountService.identity().subscribe();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitle();
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate([Constant.ERROR_404_PATH]);
      }
    });
  }

  /**
   * get page title
   */
  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  /**
   * update title
   */
  private updateTitle(): void {
    let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (!pageTitle) {
      pageTitle = 'Gateway';
    }
    this.titleService.setTitle(pageTitle);
  }

  /**
   * Update component
   */
  private async updateComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.commonObject = Helper.getCommonObject();
    if (Object.values(this.commonObject)?.every(object => !object)) {
      this.commonObject = this.commonService.getCommonObject();
    } else if (this.commonObject.userIdString) {
      setTimeout(() => {
        this.userService2.updateUserLogin(this.commonObject.userIdString, this.commonObject.userId).subscribe(
          data => {
            if (data['message'] === Constant.SOME_ERROR) {
              Helper.handleErrorUserNotExists(this.dialogService, this.store, this.translateService);
              return;
            }
          },
          error => {
            Helper.showErrorDialog(this.dialogService, this.translateService, this.translateService.instant('dialog-error.msg'));
          }
        );
      });
    }
    sessionStorage.removeItem(Constant.COMMON_INFORMATION);
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );

    // handle when F5 or enter url /{language}
    let language = this.activatedRoute.snapshot.params.language;
    if (language && this.commonObject.setting?.language) {
      if (Helper.checkValidLanguageOnUrl(language)) {
        this.router.navigate(['']);
      } else {
        this.router.navigate([Constant.ERROR_404_PATH]);
      }
      return;
    }
    if (this.commonObject.fieldComponent1 || this.commonObject.userIdString) {
      this.languageService.changeLanguage(this.commonObject.setting?.language);
      this.userId = this.commonObject.userIdString;
      var interval = null;
      interval = setInterval(() => {
        if (this.commonObject.userName) {
          this.dataService.sendData(['userName', this.commonObject.userName]);
          clearInterval(interval);
        }
      }, 50);
    }
    if (this.commonObject.fieldComponent1) {
      this.dataService.sendData(['projectName', this.commonObject.projectName]);
      let array1s = this.commonObject.fieldComponent1.split('-');
      this.openComponent(+array1s[0], +array1s[1], true);
      this.store.dispatch(
        new SaveUserInfoAction({
          user: this.commonObject.user,
          privileges: this.commonObject.privileges
        })
      );
      this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
    } else if (this.commonObject.userIdString) {
      // handle when user has not privilege View software
      this.openComponent(undefined, NUMBER_LAYOUT.LAYOUT_1);
      this.store.dispatch(
        new SaveUserInfoAction({
          user: this.commonObject.user,
          privileges: this.commonObject.privileges
        })
      );
      this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
    } else {
      language = this.handleLanguageForLoginComponent(language);
      if (this.commonObject.isLogout) {
        this.languageService.changeLanguage(this.commonObject.setting?.language);
      }
      this.commonObject.setting = undefined;
      this.store.dispatch(
        new SaveMainStateAction({
          common: this.commonObject
        })
      );
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[FIELD_COMPONENT.LoginModalComponent]);
      this.moduleLayout.clear();
      this.componentRef = this.moduleLayout.createComponent(factory);
      this.moduleName = '';
      this.colorTask = '#000';

      const sub: Subscription = this.componentRef.instance.loginSuccess.subscribe(userId => {
        this.commonObject.moduleName = 'Login';
        this.userService.find(userId).subscribe((user: any) => {
          let privileges = [];
          const isUserRoot = userId == Constant.ROOT;
          this.commonObject.userId = JSON.stringify(user.id);
          this.commonObject.userName = user.fullName;
          this.commonObject.userIdString = user.userId;
          var interval = null;
          interval = setInterval(() => {
            if (this.commonObject.userName) {
              this.dataService.sendData(['userName', this.commonObject.userName]);
              clearInterval(interval);
            }
          }, 50);
          this.userId = userId;

          forkJoin([
            this.userService2.getPrivilegesByUserRole(user.role.id, isUserRoot),
            this.userSettingService.getAllTimeZone(),
            this.userSettingService.getInfoSetting(),
            this.dataConfigService.getCustomerApiUrl(),
            this.fontService.getFontUrlPresigned()
          ]).subscribe(dataResponses => {
            privileges = dataResponses[0];
            let listTimeZone = dataResponses[1];
            let userSettingData = dataResponses[2];
            let customerApiUrl = dataResponses[3]['customer-api-url'];
            this.commonObject.user = user;
            this.commonObject.privileges = privileges;
            this.store.dispatch(
              new SaveUserInfoAction({
                user: user,
                privileges: privileges
              })
            );
            this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
            if (userSettingData) {
              let userSetting: UserSetting = userSettingData;
              userSetting.timezone = listTimeZone.find(timezone => timezone.id == +userSettingData['timezone']);
              this.commonObject.setting = userSetting;
              this.commonObject.customerApiUrl = customerApiUrl;
              this.commonObject.fonts = dataResponses[4] as any[];
              this.store.dispatch(
                new SaveMainStateAction({
                  common: this.commonObject
                })
              );
              const privilegeViews = Helper.getPrivilegeViews(privileges, user.role.id);
              const isModuleAccessPermission = privilegeViews.some(
                rolePrivilege => rolePrivilege.moduleName == MODULE_NAME[userSetting.startupModule]
              );
              let fieldComponent = isModuleAccessPermission
                ? userSetting.startupModule
                : privilegeViews.length
                ? MODULE_NAME_NEED_PROJECT.includes(privilegeViews[0].moduleName)
                  ? undefined
                  : Number(MODULE_NAME[privilegeViews[0].moduleName])
                : undefined;
              if (fieldComponent != null) {
                this.commonObject.fieldComponent1 = `${fieldComponent}-${NUMBER_LAYOUT.LAYOUT_1}`;
              }
              if (!this.commonObject.isLogout) {
                this.router.navigate(['']);
              } else {
                this.openComponent(fieldComponent, NUMBER_LAYOUT.LAYOUT_1);
              }
            }
          });
        });
      });
      this.componentRef.onDestroy(() => {
        sub.unsubscribe();
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * open component
   * @param fieldComponent FIELD_COMPONENT
   * @param numberLayout NUMBER_LAYOUT
   * @param isNoResetStore
   */
  private openComponent(fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    if (fieldComponent != null) {
      this.commonObject.fieldComponent1 = `${fieldComponent}-${numberLayout}`;
    }
    this.moduleLayout.clear();
    this.moduleLayout_1?.clear();
    this.moduleName = this.MODULE_NAME[fieldComponent == undefined ? '' : fieldComponent];
    this.colorTask = COLOR_TITLE[fieldComponent == undefined ? COLOR_TITLE.length - 1 : fieldComponent];

    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.componentRef_1) {
      this.componentRef_1.destroy();
    }
    if (fieldComponent == undefined || fieldComponent == null) {
      return;
    }
    if (!isNoResetStore) {
      Helper.resetComponentState(fieldComponent, this.store);
    }
    let factory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponents[fieldComponent]);
    let factoryMenu = this.componentFactoryResolver.resolveComponentFactory(menuComponents[fieldComponent]);
    this.moduleLayout.clear();
    this.moduleLayout_1?.clear();
    this.componentRef = this.moduleLayout.createComponent(factory);
    this.componentRef_1 = this.moduleLayout_1?.createComponent(factoryMenu);
    this.changeDetectorRef.detectChanges();
    this.commonObject.moduleName = this.moduleName;
  }

  /**
   * change component
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */
  public changeComponent(fieldComponent?: any, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    if (Helper.isChangeDataBeforeLeave(this.componentRef)) {
      this.confirmSave(fieldComponent, numberLayout, isNoResetStore);
    } else {
      this.openComponent(fieldComponent, numberLayout, isNoResetStore);
    }
  }

  /**
   * confirm save
   * @param fieldComponent
   * @param numberLayout
   * @param isNoResetStore
   */

  private confirmSave(fieldComponent?: FIELD_COMPONENT, numberLayout?: NUMBER_LAYOUT, isNoResetStore?: boolean): void {
    if (this.moduleName != this.MODULE_NAME[17]) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('layout.confirm-save'),
            button1: this.translateService.instant('layout.yes'),
            button2: this.translateService.instant('layout.no')
          }
        },
        result => {
          if (result) {
            this.menuActionService.save(this.moduleName);
            const sub = this.componentRef.instance.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.openComponent(fieldComponent, numberLayout, isNoResetStore);
              }
            });
          } else {
            this.openComponent(fieldComponent, numberLayout, isNoResetStore);
          }
        }
      );
    } else {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.please-delivery'),
          textWithHashtags: this.componentRef?.instance?.listAppOutPut?.filter(e => !e.appId)?.map(e => e.name)
        }
      });
      return;
    }
  }

  /**
   * choose program
   */
  public chooseProgram(screen: HTMLDivElement, menuSoftware: HTMLDivElement): void {
    this.projectId = +this.commonObject.projectId;
    Helper.handleScrollMenu(screen, menuSoftware);
  }

  /**
   * ngOnChanges
   */
  ngOnChanges() {
    this.updateComponent();
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit() {
    this.updateComponent();
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Resized Event
   */
  public onResized(): void {
    Helper.handleScrollMenu(this.screen1.nativeElement, this.menuSoftware1.nativeElement);
  }

  /**
   * handle language for login component
   * @param language
   */
  private handleLanguageForLoginComponent(language: string): string {
    if (language) {
      if (Helper.checkValidLanguageOnUrl(language)) {
        language = language.toLowerCase();
        this.router.navigate([language]);
        this.languageService.changeLanguage(language);
        return language;
      } else {
        this.router.navigate([Constant.ERROR_404_PATH]);
        return language;
      }
    } else if (!this.commonObject.setting?.language) {
      this.router.navigate([Constant.DEFAULT_LANGUAGE]);
      this.languageService.changeLanguage(Constant.DEFAULT_LANGUAGE);
      return Constant.EN_LANGUAGE;
    }
  }
}
